.textarea-overrideOL,
ol {
  list-style-type: decimal;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-left: 30px;
}

.textarea-overrideUL,
ul {
  list-style: disc;

  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 10px;

  margin-left: 30px;
}
.textarea-overrideP,
p {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: -25px;
  margin-left: 30px;
}
.textarea-overrideLi,
li {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: -25px;
  margin-left: 30px;
}
